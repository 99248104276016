import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

function App() {
  return (
    <Container>
      <Typography
          variant="h1"
          color="#ff6600"
          textAlign="center"
          sx={{ margin: 4 }}
          fontWeight={700}
        >
         Visit Our Office
      </Typography>
      <Typography variant="body1" color="#333" textAlign="center" sx={{ margin: 2 }}>
      Find Us on the Map
      </Typography>
      <Typography variant="h6" color="#333" textAlign="center" sx={{ margin: 2 }}>
      We’re located at No 214, Bandaragama Road, Kesbewa, Sri Lanka. Use the map below to get directions and visit our office. Our friendly team is ready to assist you with any visa or job placement inquiries.
      </Typography>
      <Grid container spacing={5}>
        {/* Other grid items for jobs or content can be added here */}
        
        {/* Last grid item for the map */}
        <Grid item xs={12}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3961.8356590998783!2d79.941871!3d6.789843!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae24fbe1cdb36ff%3A0x85de12165bec6c83!2s214%20Kesbewa-Kindelpitiya-Bandaragama%20Rd%2C%20Piliyandala!5e0!3m2!1sen!2slk!4v1730140033893!5m2!1sen!2slk"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
      </Grid>
    </Container>
  );
}

export default App;
