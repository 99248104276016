import React from 'react';
import { Box, Grid, ListItem, ListItemText, Typography } from '@mui/material';
import Img1 from '../../assets/landing/a3.jpg';

const WhoWeAreSection = () => {
  return (
    <Box minHeight="100vh" bgcolor="#fff" p={5}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        
        {/* Right Section */}
        <Grid item xs={12} md={6}>
          <Box 
            display="flex" 
            justifyContent="left" 
            alignItems="left" 
            height="100%"
            p={3}
          >
            <Box component="img" src={Img1} alt="Sky Europe Logo" width="70%" />
          </Box>
        </Grid>
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" p={3}>
            <Typography variant="h2" color="#ff6600" fontWeight={700} gutterBottom>
            Our Mission
            </Typography>
            <Typography variant="body1" color="textSecondary">
            Our mission is to connect individuals with meaningful job opportunities that enhance their professional and personal lives. We are dedicated to creating a bridge between skilled workers and businesses that rely on reliable, qualified talent. We strive to:
            </Typography>
            <br />
                <ListItemText >
                Empower Job Seekers: By offering diverse opportunities that match their skills, experience, and career aspirations.
                </ListItemText>
                <ListItemText >
                Ensure a Smooth Process: From job matching to visa support, we make the recruitment process easy and stress-free for both candidates and employers.
                </ListItemText>
                <ListItemText >
                Support Businesses: By delivering top-quality candidates who contribute to business success and growth.
                </ListItemText>
          </Box>
        </Grid>

        
      </Grid>
    </Box>
  );
};

export default WhoWeAreSection;
