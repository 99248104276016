// ServicesSection.js
import React from 'react';
import { Box, Typography, Grid, List, ListItem, ListItemText } from '@mui/material';
import servicesImage from '../../assets/landing/img3.jpg';

function ServicesSection() {
  return (
    <Box sx={{ padding: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Grid container spacing={4}>
        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={servicesImage}
            alt="Sky Europe Office"
            sx={{ width: '100%', borderRadius: '8px' }}
          />
        </Grid>

        {/* Text Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h2" color="#ff6600" fontWeight={700} gutterBottom>
            Our Services Include:
          </Typography>
          <List sx={{ marginTop: '16px' }}>
            <ListItem>
              <ListItemText
                primary={<strong>Industry-Specific Job Placements:</strong>}
                secondary="We cater to industries such as hospitality, construction, transportation, healthcare, and manufacturing, offering a wide array of job opportunities."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<strong>Customized Recruitment Solutions:</strong>}
                secondary="Our recruitment services are tailored to meet the specific needs of each client, ensuring the right fit for both candidates and employers."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<strong>Consulting and Advisory:</strong>}
                secondary="We provide guidance on best practices, industry trends, and compliance with relevant employment laws and regulations."
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ServicesSection;
