import React from 'react';
import { Grid, Card, CardContent, Typography, Button } from '@mui/material';

// Import custom images
import GlobalOpportunitiesImage from '../../assets/landing/world.gif';
import IndustryExpertiseImage from '../../assets/landing/conveyor-belt.gif';
import FastReliableProcessImage from '../../assets/landing/reliability.gif';
import SeamlessJobPlacementImage from '../../assets/landing/job-offer.gif';
import { Link } from 'react-router-dom';

const imageStyles = {
    borderStyle: 'none',
    borderWidth: '0px',
    borderRadius: '86px',
    objectFit: 'cover',
    width: '96px',
    height: 'auto',
};

const cardStyles = {
    borderColor: '#ffffff',
    border: 'solid 1px #ffffff',
    borderWidth: '1px',
    marginTop: '0',
    marginBottom: '0',
    paddingTop: '40px',
    paddingRight: '30px',
    paddingBottom: '40px',
    paddingLeft: '30px',
    backgroundColor: '#000000',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const WhyChooseUs = () => {
  return (
    <Grid container spacing={4} style={{ padding: '20px', backgroundColor: '#000' }}>
      {/* Left Section */}
      <Grid item xs={12} md={4} style={{ color: '#ff6600', textAlign: 'left' }}>
        <Typography variant="p" style={{    
                                            color: '#ff6600',
                                            fontSize: '6vw',
                                            fontStyle: 'normal',
                                            fontWeight: '700',
                                            letterSpacing: '0px',
                                            lineHeight: '0.9',
                                            textDecoration: 'none',
                                            textTransform: 'none', }}
                                            >
          Why Choose SKY EUROPE?
        </Typography>
        <Typography variant="body1" style={{ marginTop: '20px', color: '#fff' }}>
          Choose Sky Europe for our trusted expertise, personalized service, and commitment to making your international job journey smooth and successful.
        </Typography>
        <Button
        component={Link} to={'/about'}
          variant="contained"
          style={{
            marginTop: '20px',
            backgroundColor: '#ff6600',
            color: '#fff',
            textTransform: 'none',
          }}
        >
          Explore More
        </Button>
      </Grid>

      {/* Right Section - Cards */}
      <Grid item xs={12} md={8}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card style={cardStyles}>
              <CardContent style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
                <img src={GlobalOpportunitiesImage} alt="Global Opportunities" style={imageStyles} />
                <Typography variant="h6" style={{ marginTop: '10px' }}>Global Opportunities</Typography>
                <Typography variant="body2" style={{ marginTop: '10px' }}>
                  We connect you with international job opportunities across industries, ensuring your career reaches new heights beyond borders.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card style={cardStyles}>
              <CardContent style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
                <img src={IndustryExpertiseImage} alt="Industry Expertise" style={imageStyles} />
                <Typography variant="h6" style={{ marginTop: '10px' }}>Industry Expertise</Typography>
                <Typography variant="body2" style={{ marginTop: '10px' }}>
                  With years of experience in visa consultancy and recruitment, we provide expert advice tailored to your skillset and career aspirations.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card style={cardStyles}>
              <CardContent style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
                <img src={FastReliableProcessImage} alt="Fast & Reliable Process" style={imageStyles} />
                <Typography variant="h6" style={{ marginTop: '10px' }}>Fast & Reliable Process</Typography>
                <Typography variant="body2" style={{ marginTop: '10px' }}>
                  Our efficient and transparent visa application and recruitment process ensures you’re on your way to your dream job without unnecessary delays.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card style={cardStyles}>
              <CardContent style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
                <img src={SeamlessJobPlacementImage} alt="Seamless Job Placement" style={imageStyles} />
                <Typography variant="h6" style={{ marginTop: '10px' }}>Seamless Job Placement</Typography>
                <Typography variant="body2" style={{ marginTop: '10px' }}>
                  We pride ourselves on finding the right fit for both employers and job seekers, ensuring long-term satisfaction and career growth.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WhyChooseUs;
