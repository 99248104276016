import { Box, Typography, Button } from '@mui/material';
import React from 'react';
import landingImage from '../../assets/landing/banner_bg.jpg'; // Import the background image

const MainBanner = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        backgroundImage: `url(${landingImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '50vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textAlign: 'center',
      }}
    >
      {/* Dark Overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust opacity as needed
          zIndex: 1,
        }}
      />

      {/* Content on top of the overlay */}
      <Box sx={{ position: 'relative', zIndex: 2, padding: '0 20px' }}>
        {/* Banner Text */}
        <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
        Unlock Opportunities Across Diverse Sectors with
        </Typography>
        <Typography variant="h2" color='#ff6600' fontWeight={700} sx={{ mb: 4 }}>
        Sky Europe
        </Typography>
      </Box>
    </Box>
  );
};

export default MainBanner;
