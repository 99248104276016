import React from 'react';
import { Routes, Route } from 'react-router-dom';

// pages
import LandingPage from '../components/Landing/index';
import About from '../components/About/index';
import Services from '../components/ourServices/index';
import Industries from '../components/Industries/index';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/about" element={<About />} />
      <Route path="/our-jobs" element={<Services />} />
      <Route path="/industries" element={<Industries />} />
    </Routes>
  );
}

export default AppRoutes;
