// HotelCard.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Collapse,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Image from '../../assets/landing/6.gif'; // Update the path as needed

const HotelCard = () => {
  const roles = [
    { title: 'CNC Machine Operator', description: 'We supply CNC machine operators trained in operating computer-controlled machinery for precise production.' },
    { title: 'Packaging Machine Operator', description: 'Our packaging machine operators ensure efficient packaging of products in manufacturing plants.' },
    { title: 'Forklift Operator', description: 'We provide certified forklift operators for managing warehouse inventory and transportation.' },
    { title: 'Production Line Operator', description: 'Our production line operators ensure the smooth running of automated systems in factories.' },
  ];

  const [openRoleIndex, setOpenRoleIndex] = useState(null);

  const handleRoleClick = (index) => {
    setOpenRoleIndex(openRoleIndex === index ? null : index); // Toggle the clicked role
  };

  return (
    <Card
      sx={{
        maxWidth: '90%',
        margin: 'auto',
        borderRadius: '25px',
        boxShadow: '9px 4px 46px 1px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        padding: 3,
      }}
    >
      {/* Image Section */}
      <Box
        component="img"
        src={Image}
        alt="Hotel and Hospitality"
        sx={{
          width: '113px',
          height: '113px',
          margin: 'auto',
          marginBottom: 2,
        }}
      />

      {/* Content Section */}
      <CardContent>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
        Machine Operator Jobs
        </Typography>
        
        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
        Machine operators are responsible for operating and maintaining machinery used in manufacturing. These positions require attention to detail and the ability to work with complex machinery to ensure production runs smoothly.
        </Typography>

        {/* Role List with Collapsible Details in Horizontal Layout */}
        <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
          {roles.map((role, index) => (
            <Grid
              item
              key={index}
              sx={{ cursor: 'pointer', textAlign: 'center' }}
              onClick={() => handleRoleClick(index)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {openRoleIndex === index ? <ExpandMoreIcon fontSize="small" /> : <ArrowRightIcon fontSize="small" />}
                <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 'bold' }}>
                  {role.title}
                </Typography>
              </Box>
              <Collapse in={openRoleIndex === index} timeout="auto" unmountOnExit>
                <Box sx={{ paddingTop: 1, color: 'text.secondary', maxWidth: '150px', margin: 'auto' }}>
                  <Typography variant="body2">
                    {role.description}
                  </Typography>
                </Box>
              </Collapse>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HotelCard;
