import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import { Facebook, Instagram, LinkedIn, Close } from '@mui/icons-material';

import Logo from '../../assets/Logo/skylog201.png';

function Footer() {
  return (
    <Box sx={{ backgroundColor: '#1f1f1f', color: '#fff', py: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          {/* Logo and Tagline */}
          <Grid item xs={12} md={3}>
            <Box display="flex" alignItems="center">
              <img
                src={Logo} // Replace with your logo URL
                alt="Sky Europe"
                style={{ width: 50, height: 50, marginRight: 8 }}
              />
              <Typography variant="h6">Sky Europe</Typography>
            </Box>
            <Typography variant="body2" mt={2}>THE RIGHT WAY TO DREAMLAND</Typography>
            <Box mt={2}>
              <IconButton color="inherit" href="https://instagram.com" target="_blank">
                <Instagram />
              </IconButton>
              <IconButton color="inherit" href="https://facebook.com" target="_blank">
                <Facebook />
              </IconButton>
              <IconButton color="inherit" href="https://linkedin.com" target="_blank">
                <LinkedIn />
              </IconButton>
              <IconButton color="inherit">
                <Close />
              </IconButton>
            </Box>
          </Grid>

          {/* Company Links */}
          <Grid item xs={12} md={2}>
            <Typography variant="subtitle1" gutterBottom>COMPANY</Typography>
            <Link href="/about" color="inherit" underline="none" display="block">About Us</Link>
            <Link href="/about" color="inherit" underline="none" display="block">Policy</Link>
            <Link href="/about" color="inherit" underline="none" display="block">Terms and Conditions</Link>
            <Link href="/about" color="inherit" underline="none" display="block">Career</Link>
            <Link href="/about" color="inherit" underline="none" display="block">Blog</Link>
            <Link href="/about" color="inherit" underline="none" display="block">Contact Us</Link>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} md={2}>
            <Typography variant="subtitle1" gutterBottom>QUICK LINKS</Typography>
            <Link href="/" color="inherit" underline="none" display="block">Home</Link>
            <Link href="/our-jobs" color="inherit" underline="none" display="block">Our Jobs</Link>
            <Link href="/our-team" color="inherit" underline="none" display="block">Meet Our Team</Link>
            <Link href="/about" color="inherit" underline="none" display="block">About Us</Link>
            <Link href="#contact-section" color="inherit" underline="none" display="block">Contact Us</Link>
            <Link href="/about" color="inherit" underline="none" display="block">FAQ</Link>
          </Grid>

          {/* Contact Us */}
          <Grid item xs={12} md={3}>
            <Typography variant="subtitle1" gutterBottom>CONTACT US</Typography>
            <Typography variant="body2">0112 702 701</Typography>
            <Typography variant="body2">+94 76 550 8787</Typography>
            <Typography variant="body2">skyeuropjobs@gmail.com</Typography>
            <Typography variant="body2">No 214, 1st floor, Bandaragama Road, Kesbewa</Typography>
            <Typography variant="subtitle1" gutterBottom mt={2}>OFFICE HOUR</Typography>
            <Typography variant="body2">Mon–Fri, 9 AM – 5 PM</Typography>
            <Typography variant="body2">Sat-9 AM – 1.30 PM</Typography>
          </Grid>
        </Grid>
      </Container>
      <Box textAlign="center" py={2} mt={2} borderTop="1px solid #333">
      <Typography
  variant="body2"
  sx={{
    fontSize: '0.875rem', // Adjust font size for a professional look
    color: '#b8b8b8',      // Use a softer, professional color
    fontWeight: '500',     // Slightly bold for a polished appearance
    letterSpacing: '0.5px', // Add some spacing
  }}
>
  Proudly powered by <span style={{ color: '#ffffff', fontWeight: '700' }}>VEDHA8</span>
</Typography>

      </Box>
    </Box>
  );
}

export default Footer;
