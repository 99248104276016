import React from 'react';
import { Grid } from '@mui/material';
import TopNavBar from '../nav_bar/topNav';
import SideNavBar from '../nav_bar/secondNav';
import MainBanner from  './mainBanner';
import OurJobs from  './ourJobs';
import AboutUs from  './aboutUs';
import Cont from  './cont';
import OurTeem from  './Team';
import Job from './Job';
import Hero from './HeroSection';
import Map_Box from './Map';
import Contact from './contactUs';
import Footer from '../footer/index';

const Index = () => {
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <TopNavBar />
      </Grid>
      <Grid item xs={12} >
        <SideNavBar />
      </Grid>
      <Grid item xs={12} >
        <MainBanner />
      </Grid>
      <Grid item xs={12} >
        <OurJobs />
      </Grid>
      <Grid item xs={12} >
        <AboutUs />
      </Grid>
      <Grid item xs={12} >
        <Cont />
      </Grid>
      <Grid item xs={12} >
        <OurTeem />
      </Grid>
      <br/>
      <br/>
      <br/>
      <Grid item xs={12} >
        <Job />
      </Grid>
      <Grid item xs={12} >
        <Hero />
      </Grid>
      <Grid item xs={12} >
        <Map_Box />
      </Grid>
      <Grid item xs={12} >
        <Contact id="contact-section"/>
      </Grid>
      <Grid item xs={12} >
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Index;
