import React from 'react';
import { Grid, Card, CardContent, Typography, Button } from '@mui/material';

import Img1 from '../../assets/landing/hotel-bell.gif'; 
import Img2 from '../../assets/landing/crane.gif'; 
import Img3 from '../../assets/landing/packing.gif'; 
import Img4 from '../../assets/landing/driver.gif'; 
import Img5 from '../../assets/landing/cleaning-trolley.gif'; 
import Img6 from '../../assets/landing/press-machine.gif';
import { Link } from 'react-router-dom';

const jobData = [
  {
    title: 'General Hotel & Hospitality',
    description: 'Join the dynamic world of hotel and hospitality. From front desk management to housekeeping, this industry offers endless opportunities for those with a passion for customer service',
    image: Img1,
  },
  {
    title: 'Construction',
    description: 'Be part of building the future. Whether it’s operating machinery or skilled labor, the construction sector offers hands-on roles that make an impact.',
    image: Img2,
  },
  {
    title: 'Packing Worker',
    description: 'Ensure the safe and efficient packaging of products in various industries. Attention to detail and efficiency are key in this vital role.',
    image: Img3,
  },
  {
    title: 'Driver',
    description: 'Take the wheel and drive towards a successful career. Opportunities include both personal and commercial driving roles.',
    image: Img4,
  },
  {
    title: 'Housemaid',
    description: 'Assist in maintaining households with care and attention to detail. This role is ideal for those who take pride in organization and cleanliness.',
    image: Img5,
  },
  {
    title: 'Machine Operator',
    description: 'Operate advanced machinery and equipment in various industries. Skilled workers are always in demand to ensure production runs smoothly.',
    image: Img6,
  },
];

const OurJobs = () => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          color="#ff6600"
          textAlign="center"
          sx={{ margin: 4 }}
          fontWeight={700}
        >
          Our Jobs
        </Typography>
        <Typography variant="h6" color="#333" textAlign="center" sx={{ margin: 2 }}>
          We provide opportunities in a wide range of industries
        </Typography>
      </Grid>
      <Grid container spacing={4} padding={4}>
        {jobData.map((job, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Initial shadow
                transition: 'transform 0.3s, box-shadow 0.3s', // Smooth transition for hover effects
                '&:hover': {
                  transform: 'translateY(-10px)', // Lift up on hover
                  boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)', // Stronger shadow on hover
                },
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <img src={job.image} alt={job.title} style={{ height: '100px', marginBottom: '10px' }} />
                <Typography variant="h5" component="div">
                  {job.title}
                </Typography>
                <Typography variant="h6" color="text.secondary" marginTop={1}>
                  {job.description}
                </Typography>
                <Button component={Link} to={'/our-jobs'} sx={{ marginTop: 2, color: '#252525' }}>
                  Read More
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 4 }}>
        <Button variant="contained" component={Link} to={'/our-jobs'} sx={{
            backgroundColor: '#ff6600',
            padding:'15px',
            color:'#ffffff'
        }}>
          Explore More
        </Button>
      </Grid>
    </Grid>
  );
};

export default OurJobs;
