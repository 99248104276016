import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

import Img1 from '../../assets/landing/croatia.png';
import Img2 from '../../assets/landing/serbia.png';
import Img3 from '../../assets/landing/israel.png';
import Img4 from '../../assets/landing/romania.png';
import Img5 from '../../assets/landing/united-arab-emirates.png';

// Dummy data for countries
const countries = [
  { name: 'Croatia', image: Img1 },
  { name: 'Serbia', image: Img2 },
  { name: 'Israel', image: Img3 },
  { name: 'Romania', image: Img4 },
  { name: 'UAE', image: Img5 },
];

const CountriesWeServe = () => {
  return (
    <Box sx={{ padding: { xs: '1rem', md: '2rem' } }}>
      <Typography
        variant="h1"
        color="#ff6600"
        textAlign="center"
        sx={{ margin: { xs: 2, md: 4 }, fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }}
        fontWeight={700}
      >
        Countries We Serve
      </Typography>
      <Typography
        variant="h6"
        color="#333"
        textAlign="center"
        sx={{ margin: { xs: 2, md: 4 }, fontSize: { xs: '1rem', sm: '1.25rem' } }}
      >
        Our trustworthy and dedicated team at Sky Europe is committed to providing the best visa consultancy and recruitment services, ensuring clients achieve their dreams seamlessly.
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {countries.map((country, index) => (
          <Grid
            item
            xs={6}
            sm={4}
            md={2}
            key={index}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <Box
              component="img"
              src={country.image}
              alt={country.name}
              sx={{
                width: { xs: '80%', sm: '100%' },
                height: 'auto',
                borderRadius: '10px',
                marginBottom: '0.5rem',
              }}
            />
            <Typography variant="subtitle1" align="center" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
              {country.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CountriesWeServe;
