import React from 'react';
import { Grid } from '@mui/material';
import TopNavBar from '../nav_bar/topNav';
import SideNavBar from '../nav_bar/secondNav';
import MainBanner from  './mainBanner';
import Card1 from  './card1';
import Card2 from  './card2';
import Card3 from  './card3';
import Card4 from  './card4';
import Card5 from  './card5';
import Card6 from  './card6';
import Card7 from  './card7';
import Card8 from  './card8';
import Card9 from  './card9';
import ContactUs from './cont'
import Footer from '../footer/index';

const Index = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item xs={12}>
        <TopNavBar />
      </Grid>
      <Grid item xs={12} >
        <SideNavBar />
      </Grid>
      <Grid item xs={12} >
        <MainBanner />
      </Grid>
      <Grid item xs={12} >
        <Card1 />
      </Grid>
      <Grid item xs={12} >
        <Card2 />
      </Grid>
      <Grid item xs={12} >
        <Card3 />
      </Grid>
      <Grid item xs={12} >
        <Card4 />
      </Grid>
      <Grid item xs={12} >
        <Card5 />
      </Grid>
      <Grid item xs={12} >
        <Card6 />
      </Grid>
      <Grid item xs={12} >
        <Card7 />
      </Grid>
      <Grid item xs={12} >
        <Card8 />
      </Grid>
      <Grid item xs={12} >
        <Card9 />
      </Grid>
      <Grid item xs={12} >
        <ContactUs />
      </Grid>
      <Grid item xs={12} >
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Index;
