import React from 'react';
import { Box, Container, Typography, Button, Grid } from '@mui/material';
import { styled } from '@mui/system';

import Vid from '../../assets/landing/se-1vid.mp4'
import { Link } from 'react-router-dom';

// Styled components for consistency with the original design
const SectionWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff', // Match background color
  padding: '40px 0',
  fontFamily: 'Arial, sans-serif', // Replace with the exact font-family if needed
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  color: '#f16c00', // Match the orange color for "About Us"
  marginBottom: '20px',
}));

const Description = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: '#333', // Match text color
  marginBottom: '30px',
  lineHeight: '1.6',
}));

const VideoWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '600px', // Adjust as needed
  margin: '20px 0',
}));

const ExploreButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#f16c00', // Match button color
  color: '#fff',
  fontWeight: 'bold',
  padding: '10px 20px',
  textTransform: 'none',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#e65b00', // Slightly darker shade for hover effect
  },
}));

const AboutUs = () => {
  return (
    <SectionWrapper>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          {/* Left Side (Text Content) */}
          <Grid item xs={12} md={6}>
          <Typography
          variant="h1"
          color="#ff6600"
          sx={{ margin: 0 }}
          fontWeight={700}
        >
          About Us
        </Typography>
        <br/>
            <Description>
              Sky Europe connects skilled professionals to global opportunities in hospitality, construction, and healthcare.
              Our experienced team offers curated job placements, comprehensive relocation support, and access to reputable
              employers worldwide. With our expertise, we guide you through every step of your international career journey.
              Contact Sky Europe today to explore how we can help you achieve your professional goals abroad.
            </Description>
            <ExploreButton component={Link} to={'/about'} variant="contained">Explore More</ExploreButton>
          </Grid>

          {/* Right Side (Video) */}
          <Grid item xs={12} md={6}>
            <VideoWrapper>
                <video src={Vid} autoPlay loop controls width="100%" />
            </VideoWrapper>
            </Grid>
        </Grid>
      </Container>
    </SectionWrapper>
  );
};

export default AboutUs;
