import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import valuesImage from '../../assets/landing/img5.png';

function ValuesSection() {
  return (
    <Box sx={{ padding: { xs: '20px', md: '40px' }, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Grid container spacing={4}>
        
        {/* Text Section */}
        <Grid item xs={12} md={6}>
          <Typography 
            variant="h2" 
            color="#ff6600" 
            fontWeight={700} 
            gutterBottom
            fontSize={{ xs: '1.75rem', sm: '2.5rem', md: '3rem' }}
          >
            Licensed Foreign Employment Agency
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ marginTop: '16px', color: 'text.secondary' }}
            fontSize={{ xs: '0.875rem', sm: '1rem' }}
          >
            Sky Europe Job Agency (Pvt) Ltd is officially registered under the Companies <span style={{
              fontWeight:'700'
            }}> Act No. 7 of 2007 </span> in Sri Lanka. Our registration number is <span style={{
              fontWeight:'700'
            }}> PV 00300462 </span>, and we are fully licensed under License No. 3672 to operate as a private limited company offering recruitment and job placement services both locally and internationally.
          </Typography>
        </Grid>

        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={valuesImage}
            alt="Sky Europe Office"
            sx={{ width: { xs: '100%', sm: '90%', md: '100%' }, borderRadius: '8px' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ValuesSection;
