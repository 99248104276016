// MainBanner.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import backgroundImage from '../../assets/landing/se-02-2-scaled.jpeg'; // Adjust this path if needed

const MainBanner = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '60vh',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        color: 'black',
        textAlign: 'left',
      }}
    >
      {/* Overlay for semi-transparent effect */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.6)', // White overlay with transparency
          zIndex: 1,
        }}
      />

      {/* Content on top of the overlay */}
      <Box sx={{ position: 'relative', zIndex: 2, maxWidth: '800px', px: 3 }}>
        <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: { xs: '2rem', sm: '3rem' }, mb: 2 }}>
          Find Your Path to Success with Sky Europe
        </Typography>
        <Typography variant="h6" sx={{ mb: 4, fontSize: { xs: '1rem', sm: '1.25rem' } }}>
          Your Trusted Agency for International Opportunities
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#FF5722', // Orange color
            color: '#fff',
            padding: '10px 20px',
            fontSize: '16px',
            fontWeight: 'bold',
            textTransform: 'none',
            '&:hover': { backgroundColor: '#e64a19' }, // Slightly darker orange on hover
          }}
        >
          Apply Now
        </Button>
      </Box>
    </Box>
  );
};

export default MainBanner;
