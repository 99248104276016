// HotelCard.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Collapse,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Image from '../../assets/landing/7.gif'; // Update the path as needed

const HotelCard = () => {
  const roles = [
    { title: 'Auto Mechanic', description: 'We offer auto mechanics skilled in diagnosing and repairing a wide range of vehicle issues.' },
    { title: 'Diesel Mechanic', description: 'Our diesel mechanics are experts in servicing and maintaining diesel-powered engines and machinery.' },
    { title: 'Industrial Machinery Mechanic', description: 'We provide industrial mechanics who maintain and repair large-scale factory machinery.' },
    { title: 'Aircraft Mechanic', description: 'Our aircraft mechanics are licensed to perform maintenance and repairs on a variety of aircraft types.' },
  ];

  const [openRoleIndex, setOpenRoleIndex] = useState(null);

  const handleRoleClick = (index) => {
    setOpenRoleIndex(openRoleIndex === index ? null : index); // Toggle the clicked role
  };

  return (
    <Card
      sx={{
        maxWidth: '90%',
        margin: 'auto',
        borderRadius: '25px',
        boxShadow: '9px 4px 46px 1px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        padding: 3,
      }}
    >
      {/* Image Section */}
      <Box
        component="img"
        src={Image}
        alt="Hotel and Hospitality"
        sx={{
          width: '113px',
          height: '113px',
          margin: 'auto',
          marginBottom: 2,
        }}
      />

      {/* Content Section */}
      <CardContent>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
        Mechanic Jobs
        </Typography>
        
        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
        Mechanics are essential in maintaining and repairing vehicles, machinery, and equipment. With roles ranging from automotive repair to heavy machinery maintenance, mechanic jobs offer challenging yet rewarding career paths for individuals skilled in technical troubleshooting.
        </Typography>

        {/* Role List with Collapsible Details in Horizontal Layout */}
        <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
          {roles.map((role, index) => (
            <Grid
              item
              key={index}
              sx={{ cursor: 'pointer', textAlign: 'center' }}
              onClick={() => handleRoleClick(index)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {openRoleIndex === index ? <ExpandMoreIcon fontSize="small" /> : <ArrowRightIcon fontSize="small" />}
                <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 'bold' }}>
                  {role.title}
                </Typography>
              </Box>
              <Collapse in={openRoleIndex === index} timeout="auto" unmountOnExit>
                <Box sx={{ paddingTop: 1, color: 'text.secondary', maxWidth: '150px', margin: 'auto' }}>
                  <Typography variant="body2">
                    {role.description}
                  </Typography>
                </Box>
              </Collapse>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HotelCard;
