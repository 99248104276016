import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Img1 from '../../assets/landing/img4.jpg';

const WhoWeAreSection = () => {
  return (
    <Box minHeight="100vh" bgcolor="#fff" p={{ xs: 2, md: 5 }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        
        {/* Right Section */}
        <Grid item xs={12} md={6}>
          <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            height="100%"
            p={3}
          >
            <Box 
              component="img" 
              src={Img1} 
              alt="Sky Europe Logo" 
              width={{ xs: '100%', sm: '80%', md: '70%' }} 
            />
          </Box>
        </Grid>
        
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" p={3}>
            <Typography 
              variant="h2" 
              color="#ff6600" 
              fontWeight={700} 
              gutterBottom
              fontSize={{ xs: '1.75rem', sm: '2.5rem', md: '3rem' }}
            >
              Company Registration
            </Typography>
            <Typography 
              variant="body1" 
              color="textSecondary"
              fontSize={{ xs: '0.875rem', sm: '1rem' }}
            >
              Sky Europe Job Agency (PVT) LTD is officially licensed under the Sri Lanka Bureau of Foreign Employment (License No. 3672). This certification allows us to operate as a foreign employment agency, ensuring that we meet all legal requirements and uphold industry standards. Our license is valid for 12 months, issued on the 10th of October 2024. You can view our official certificate below for transparency and compliance.
            </Typography>
          </Box>
        </Grid>
        
      </Grid>
    </Box>
  );
};

export default WhoWeAreSection;
