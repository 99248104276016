// ValuesSection.js
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import valuesImage from '../../assets/landing/img2.jpg';

function ValuesSection() {
  return (
    <Box sx={{ padding: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Grid container spacing={4}>
        {/* Text Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h2" color="#ff6600" fontWeight={700} gutterBottom>
            Our Values
          </Typography>
          <Typography variant="body1" sx={{ marginTop: '16px', color: 'text.secondary' }}>
            At Sky Europe, we operate on a foundation of core values that guide everything we do:
          </Typography>
          <Box sx={{ marginTop: '16px' }}>
            <Typography variant="body1">
              <strong>Integrity:</strong> We believe in honest, transparent, and ethical recruitment processes.
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '8px' }}>
              <strong>Excellence:</strong> We are committed to delivering top-notch services to both our candidates and partner employers.
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '8px' }}>
              <strong>Customer Focus:</strong> We prioritize the needs and aspirations of our clients and candidates, tailoring our services to meet their specific goals.
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '8px' }}>
              <strong>Global Reach:</strong> We believe in breaking down geographical barriers, providing opportunities that allow candidates to thrive in new environments and cultures.
            </Typography>
          </Box>
        </Grid>

        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={valuesImage}
            alt="Sky Europe Office"
            sx={{ width: '100%', borderRadius: '8px' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ValuesSection;
