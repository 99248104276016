// HotelCard.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Collapse,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Image from '../../assets/landing/9.gif'; // Update the path as needed

const HotelCard = () => {
  const roles = [
    { title: 'Food Delivery Rider', description: 'We offer experienced riders to handle food delivery services for platforms like Uber Eats and Deliveroo.' },
    { title: 'Courier Rider', description: 'Our courier riders ensure fast and efficient package delivery within urban areas.' },
    { title: 'E-Bike Rider', description: 'We provide e-bike riders skilled in handling electric bikes for eco-friendly deliveries.' },
    { title: 'Package Delivery Rider', description: 'Our package delivery riders are available for companies requiring reliable delivery services.' },
  ];

  const [openRoleIndex, setOpenRoleIndex] = useState(null);

  const handleRoleClick = (index) => {
    setOpenRoleIndex(openRoleIndex === index ? null : index); // Toggle the clicked role
  };

  return (
    <Card
      sx={{
        maxWidth: '90%',
        margin: 'auto',
        borderRadius: '25px',
        boxShadow: '9px 4px 46px 1px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        padding: 3,
      }}
    >
      {/* Image Section */}
      <Box
        component="img"
        src={Image}
        alt="Hotel and Hospitality"
        sx={{
          width: '113px',
          height: '113px',
          margin: 'auto',
          marginBottom: 2,
        }}
      />

      {/* Content Section */}
      <CardContent>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
        Bike Rider Jobs
        </Typography>
        
        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
        Bike riders are in high demand for delivery services, especially in urban areas. Whether delivering food or packages, this job offers flexible hours and is ideal for individuals who enjoy cycling and being on the move.
        </Typography>

        {/* Role List with Collapsible Details in Horizontal Layout */}
        <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
          {roles.map((role, index) => (
            <Grid
              item
              key={index}
              sx={{ cursor: 'pointer', textAlign: 'center' }}
              onClick={() => handleRoleClick(index)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {openRoleIndex === index ? <ExpandMoreIcon fontSize="small" /> : <ArrowRightIcon fontSize="small" />}
                <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 'bold' }}>
                  {role.title}
                </Typography>
              </Box>
              <Collapse in={openRoleIndex === index} timeout="auto" unmountOnExit>
                <Box sx={{ paddingTop: 1, color: 'text.secondary', maxWidth: '150px', margin: 'auto' }}>
                  <Typography variant="body2">
                    {role.description}
                  </Typography>
                </Box>
              </Collapse>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HotelCard;
