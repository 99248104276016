import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

function TeamMemberCard({ name, role, image }) {
  return (
    <Card
      sx={{
        maxWidth: 345,
        height: 580,  // Set a fixed height for uniformity
        textAlign: 'center',
        borderRadius: 0,
        borderBottomColor: '#ff6600',
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid',
        paddingTop: '40px',
        paddingRight: '30px',
        paddingBottom: '40px',
        paddingLeft: '30px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        display: 'flex',  // Align content vertically
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <CardContent>
        <img
          src={image}
          alt={name}
          style={{
            borderRadius: '100px',
            aspectRatio: '3 / 4',
            objectFit: 'cover',
            width: '230px',
            height: 'auto',
          }}
        />
        <Typography variant="h4" fontWeight={600} sx={{ marginTop: 2 }}>
          {name}
        </Typography>
        <Typography variant="body1" fontWeight="bold" fontSize={20} color="text.secondary">
          {role}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default TeamMemberCard;
