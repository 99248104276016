// HotelCard.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Collapse,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Image from '../../assets/landing/3.gif'; // Update the path as needed

const HotelCard = () => {
  const roles = [
    { title: 'Delivery Driver', description: 'We provide delivery drivers experienced with food and package delivery services like Uber Eats and more.' },
    { title: 'Taxi Driver', description: 'Our taxi drivers are licensed and experienced in offering safe and reliable transport services.' },
    { title: 'Long-Haul Truck Driver', description: 'We offer certified truck drivers for long-distance transportation of goods across regions.' },
    { title: 'Company Chauffeur', description: 'Our chauffeurs provide professional driving services for corporate clients, ensuring punctuality and comfort.' },
    ];

  const [openRoleIndex, setOpenRoleIndex] = useState(null);

  const handleRoleClick = (index) => {
    setOpenRoleIndex(openRoleIndex === index ? null : index); // Toggle the clicked role
  };

  return (
    <Card
      sx={{
        maxWidth: '90%',
        margin: 'auto',
        borderRadius: '25px',
        boxShadow: '9px 4px 46px 1px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        padding: 3,
      }}
    >
      {/* Image Section */}
      <Box
        component="img"
        src={Image}
        alt="Hotel and Hospitality"
        sx={{
          width: '113px',
          height: '113px',
          margin: 'auto',
          marginBottom: 2,
        }}
      />

      {/* Content Section */}
      <CardContent>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
        Driver Jobs
        </Typography>
        
        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
        Driving jobs range from local delivery services to long-haul trucking. These positions are ideal for individuals who enjoy the freedom of the open road and providing efficient transport services. From food delivery services to corporate chauffeurs, driving roles cater to various skill levels and experiences.
        </Typography>

        {/* Role List with Collapsible Details in Horizontal Layout */}
        <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
          {roles.map((role, index) => (
            <Grid
              item
              key={index}
              sx={{ cursor: 'pointer', textAlign: 'center' }}
              onClick={() => handleRoleClick(index)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {openRoleIndex === index ? <ExpandMoreIcon fontSize="small" /> : <ArrowRightIcon fontSize="small" />}
                <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 'bold' }}>
                  {role.title}
                </Typography>
              </Box>
              <Collapse in={openRoleIndex === index} timeout="auto" unmountOnExit>
                <Box sx={{ paddingTop: 1, color: 'text.secondary', maxWidth: '150px', margin: 'auto' }}>
                  <Typography variant="body2">
                    {role.description}
                  </Typography>
                </Box>
              </Collapse>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HotelCard;
