import React from "react";
import { Box, Button, Typography } from "@mui/material";
import backgroundImage from "../../assets/landing/ctabg.jpg";

function HeroSection() {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "#fff",
        textAlign: "center",
        padding: "0 20px",
        overflow: "hidden",
      }}
    >
      {/* Dark Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay with 60% opacity
          zIndex: 1,
        }}
      />

      {/* Content */}
      <Box sx={{ position: "relative", zIndex: 2 }}>
        <Typography variant="h2" fontWeight="bold" mb={2}>
          THE RIGHT WAY TO DREAMLAND
        </Typography>
        <Typography variant="body1" mb={4}>
          Reflects our commitment to guiding you on a reliable and seamless path to achieving your dreams of working abroad.
        </Typography>
        <Box>
          <Button
            variant="contained"
            href="tel:+94765508787" 
            color="warning"
            sx={{
              mr: 2,
              px: 4,
              py: 1.5,
              fontSize: "16px",
              fontWeight: "bold",
              backgroundColor: "#FF7A00",
              "&:hover": {
                backgroundColor: "#FF9A33",
              },
            }}
          >
            Schedule Quick Call
          </Button>
          <Button
            variant="outlined"
            sx={{
              px: 4,
              py: 1.5,
              fontSize: "16px",
              fontWeight: "bold",
              borderColor: "#fff",
              color: "#fff",
              "&:hover": {
                borderColor: "#fff",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
            }}
          >
            Apply Now
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default HeroSection;
