// HotelCard.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Collapse,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Image from '../../assets/landing/hotel-bell.gif'; // Update the path as needed

const HotelCard = () => {
  const roles = [
    { title: 'Receptionist', description: 'We provide skilled receptionists with experience in guest management and customer service for hotels.' },
    { title: 'Housekeeping', description: 'Our team includes trained housekeeping staff proficient in cleaning and maintaining rooms in hotels.' },
    { title: 'Chef/Cook', description: 'We offer experienced chefs and cooks specializing in various cuisines for hotel kitchens.' },
    { title: 'Waiter/Waitress', description: 'Our waitstaff are trained to deliver excellent table service in restaurants and hotel dining areas.' },
    { title: 'Front Desk Manager', description: 'We provide front desk managers with experience in overseeing hotel operations and ensuring guest satisfaction.' },
  ];

  const [openRoleIndex, setOpenRoleIndex] = useState(null);

  const handleRoleClick = (index) => {
    setOpenRoleIndex(openRoleIndex === index ? null : index); // Toggle the clicked role
  };

  return (
    <Card
      sx={{
        maxWidth: '90%',
        margin: 'auto',
        borderRadius: '25px',
        boxShadow: '9px 4px 46px 1px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        padding: 3,
      }}
    >
      {/* Image Section */}
      <Box
        component="img"
        src={Image}
        alt="Hotel and Hospitality"
        sx={{
          width: '113px',
          height: '113px',
          margin: 'auto',
          marginBottom: 2,
        }}
      />

      {/* Content Section */}
      <CardContent>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          General Hotel & Hospitality
        </Typography>
        
        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
          The hotel industry offers a wide range of job opportunities. Whether you’re passionate about guest service, managing hotel operations, or culinary arts, there’s a role for you. From housekeeping to front desk management, hotel jobs provide opportunities for career growth in a fast-paced, guest-focused environment.
        </Typography>

        {/* Role List with Collapsible Details in Horizontal Layout */}
        <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
          {roles.map((role, index) => (
            <Grid
              item
              key={index}
              sx={{ cursor: 'pointer', textAlign: 'center' }}
              onClick={() => handleRoleClick(index)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {openRoleIndex === index ? <ExpandMoreIcon fontSize="small" /> : <ArrowRightIcon fontSize="small" />}
                <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 'bold' }}>
                  {role.title}
                </Typography>
              </Box>
              <Collapse in={openRoleIndex === index} timeout="auto" unmountOnExit>
                <Box sx={{ paddingTop: 1, color: 'text.secondary', maxWidth: '150px', margin: 'auto' }}>
                  <Typography variant="body2">
                    {role.description}
                  </Typography>
                </Box>
              </Collapse>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HotelCard;
