import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Img1 from '../../assets/Logo/Artboard-2@4x-20-1536x1536.jpg';

const WhoWeAreSection = () => {
  return (
    <Box minHeight="100vh" bgcolor="#fff" p={5}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" p={3}>
            <Typography variant="h2" color="#ff6600" fontWeight={700} gutterBottom>
              Who We Are
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Sky Europe is a leading international recruitment agency, specializing in connecting job seekers with a wide range of employment opportunities in Romania and other European countries. With a commitment to excellence and a passion for helping people, we bridge the gap between skilled professionals and top employers across various industries. From hospitality and construction to healthcare and manufacturing, we provide tailored solutions to meet the needs of both job seekers and businesses.
            </Typography>
            <br />
            <Typography variant="body1" color="textSecondary">
              At Sky Europe, we believe in opening doors to life-changing opportunities, empowering individuals to pursue their dream careers abroad.
            </Typography>
          </Box>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={6}>
          <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            bgcolor="#000" 
            height="100%"
            p={3}
          >
            <Box component="img" src={Img1} alt="Sky Europe Logo" width="70%" />
          </Box>
        </Grid>
        
      </Grid>
    </Box>
  );
};

export default WhoWeAreSection;
